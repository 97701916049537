import React, {Component} from 'react';
import axios from 'axios';

// import logo from '../../assets/logo_tagline.png';
import './Short.css'

class Short extends Component {
    async componentDidMount () {
        if(this.props.match.params.id.length !== 7){
            window.location.href = 'error/404';
        }
        // setup cookie
        const name = "campId";
        const campId = this.props.match.params.id;
        const expDate = new Date();
        const expDays = 30;
        expDate.setTime(expDate.getTime() + (expDays * 24 * 60 * 60 * 1000));
        const cookie = `${name}=${campId}; expires=${expDate.toUTCString()}`;
        // setup for axios request.
        let body = {
            bearer: process.env.REACT_APP_LEAD_WIRE_PRISMA_BEARER,
        };
        let link = window.location.href;
        // let link = 'https://www.ldwr-test.app/';
        let wwwIndex = link.indexOf('www.');
        if(wwwIndex !== -1) {
            let part1 = link.substring(0,wwwIndex);
            let part2 = link.substring(wwwIndex+4, link.length);
            body["URL"] = part1 + part2;
        } else {
            body["URL"] = link;
        }
        
        if(document.cookie) {
            const browserCookie = this.getCookie(name);
            if(campId === browserCookie) {
                body["countFlag"] = false;
                // redirect without click count
            } else {
                document.cookie=cookie;
                body["countFlag"] = true;
                // redirect with click count.   
            }
        } else {
            document.cookie=cookie; 
            body["countFlag"] = true; 
            // redirect with click count
        }
        let bodySend = JSON.stringify(body);
        await axios({
            method: 'POST',
            url: process.env.REACT_APP_LEADWIRE_API + '/shortURL',
            data: bodySend,
            headers: {
                'Content-Type': 'application/json'
            },
            json: true
        }).then(res => {
            if(res.data.data.longURL) {
                if(!res.data.data.longURL.match('https://') && !res.data.data.longURL.match('http://')) {
                    let url = 'https://' + res.data.data.longURL;
                    window.location.href = url;
                } else {
                    window.location.href = res.data.data.longURL;
                }
            }
        }).catch(err => {
            window.location.href = 'error/404';
        })               
    }

    getCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for(let i=0;i < ca.length;i++) {
            let c = ca[i];
            while (c.charAt(0)===' ')
                c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0)
                return c.substring(nameEQ.length,c.length);
        }
        return null;
    }
    render() { 
        return ( 
            <div style={{display:'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', flexDirection: 'column'}} className="errorPage">
                {/*<img className='img' alt="" src={logo} />*/}
                {/*<h1 style={{textAlign: 'center'}}> You will be redirected shortly.</h1>*/}
            </div>
        );
    }
}

export default Short;