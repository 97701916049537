import axios from "axios";

const PRISMA_POST = async (query) => {
    try {
        return await axios({
            method: 'POST',
            url: process.env.REACT_APP_LEAD_WIRE_PRISMA,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${process.env.REACT_APP_LEAD_WIRE_PRISMA_BEARER}`,
            },
            data: JSON.stringify({ query }),
        });
    } catch (err) {
        console.log('Error while communicating with prisma client: ', err);
        throw err;
    }
};

export default PRISMA_POST;