import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

// views
import MainPage from "./views/MainPage/MainPage";
import Short from "./views/Short/Short";
import Form from "./views/Form/Form";
import Success from "./views/Form/Success";
import CouponVerify from "./views/Coupon/CouponVerify";
import CouponQR from "./views/Coupon/CouponQR";
import Error from "./views/Error/Error";

class App extends Component {
  state = {  }
  render() { 
    return ( 
      <Router>
        <Switch>
          <Route exact path= "/" component={MainPage}/>
          <Route exact path= "/success" component={Success}/>
          <Route exact path= "/:id" component={Short}/>
          <Route exact path= "/qr/:id" component={Form}/>
          <Route exact path= "/c/:hash" component={CouponQR}/>
          <Route exact path= "/vc/:hash" component={CouponVerify}/>
          <Route exact path="/error/404" component={Error}/>
          <Redirect to="/error/404"/>
        </Switch>
      </Router>
     );
  }
}
 
export default App;