import React, { Component } from 'react';
import {
    Card
} from 'react-bootstrap';
import moment from 'moment';
import 'react-phone-input-2/lib/bootstrap.css'
import 'bootstrap/dist/css/bootstrap.css';
import './CouponQR.css'
import { BsCheckCircle, BsExclamationCircle } from "react-icons/bs";
import PRISMA_POST from '../../common/requests';

class CouponVerify extends Component {
    state = {
        loading: true,
        isRedeemed: false,
        expired: false,
        active: false
    };

    async componentDidMount() {
        const hash = this.props.match.params.hash;
        let clientCouponQuery = `
        query {
            clientCouponByHash(hash: "${hash}"){
                isRedeemed
                coupon{
                    id
                    active
                    createdAt
                    expiration
                    redeemCount
                    limitRedemptions
                }
            }
          }
        `;

        let updateClientCoupon = `
        mutation {
            updateClientCouponByHash(hash: "${hash}", isRedeemed: true){
                id
            }
        }
        `;

        let cMutationResponse = await PRISMA_POST(clientCouponQuery);
        let clientCoupon = cMutationResponse.data.data.clientCouponByHash;
        // check client coupon limitRedemptions Boolean
        if (cMutationResponse.status < 300 && clientCoupon) {
            // if limitRedemption is true, execute next mutation
            if (clientCoupon.coupon.limitRedemptions && !clientCoupon.isRedeemed) {
                await PRISMA_POST(updateClientCoupon);
                let { coupon } = clientCoupon;
                let updateCoupon = `
                    mutation {
                        updateCoupon(id: "${coupon.id}", redeemCount: ${coupon.redeemCount + 1}){
                            id
                        }
                    }
                `;
                await PRISMA_POST(updateCoupon);
            }
            this.setState({
                isRedeemed: clientCoupon.isRedeemed,
                active: clientCoupon.coupon.active,
                expired: moment(clientCoupon.coupon.expiration).isBefore(new Date())
            });
        }
        this.setState({loading: false, ...this.getMessage()});
    }

    getMessageIcon() {
        if (this.state.isRedeemed || !this.state.active || this.state.expired) {
            return (<BsExclamationCircle size={60} style={{ color: 'red' }} />)
        }
        return (<BsCheckCircle size={60} style={{ color: 'green' }} />)
    }

    getMessage() {
        let title, description;
        if (!this.state.active) {
            title = "Not Valid!";
            description = "This coupon is either inactive or nonexistant.";
        }
        else if (this.state.expired) {
            title = "Expired!";
            description =  "This coupon is expired.";
        }
        else if (this.state.isRedeemed) {
            title = "Already redeemed!";
            description = "It appears this coupon was redeemed once before.";
        }
        else {
            title = "Success!";
            description = "This coupon has been successfully redeemed.";
        }

        return { title, description };
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="center">
                    <Card className="p-4 shadow lw-card" >
                        <Card.Title className="text-center">
                            Loading...
                        </Card.Title>
                        <hr />
                        <Card.Subtitle className="text-muted text-center">
                            Please wait
                        </Card.Subtitle>
                    </Card>
                </div>
            )
        }
        return (
            <div className="center">
                <Card className="p-4 shadow lw-card" >
                    <div className="center" style={{ padding: '10px' }}>
                        {this.getMessageIcon()}
                    </div>
                    <Card.Body>
                        <Card.Title className="text-center">
                            {this.state.title}
                        </Card.Title>
                        <hr />
                        <Card.Subtitle className="text-muted text-center">
                            {this.state.description}
                        </Card.Subtitle>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

export default CouponVerify;