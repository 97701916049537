import React, { Component } from 'react';

import logo from '../../assets/logo_tagline.png';

class Error extends Component {
    state = {  }
    render() { 
        return ( 
            <div style={{display:'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', flexDirection: 'column'}} className="errorPage">
                <img className='img' alt="" src={logo}></img>
                <h1 style={{textAlign: 'center'}}> The page you're looking for doesn't exist.</h1>
                <h3 style={{textAlign: 'center'}}> It seems the link you're attempting to reach is unavailable.</h3>
            </div>
         );
    }
}
 
export default Error;