import React, {Component} from 'react';

import logo from '../../assets/logo_tagline.png';
import './MainPage.css'

class MainPage extends Component {
    state = {  }
    render() { 
        return ( 
            <div style={{display:'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', flexDirection: 'column'}} className="errorPage">
            <img className='img' alt="" src={logo} />
            <h1 style={{textAlign: 'center'}}> LeadWire URL Shortener</h1>
        </div>
         );
    }
}
 
export default MainPage;