import React, { Component } from 'react';
import axios from 'axios';
import {
    Card,
    Form,
    FormGroup,
    Button
} from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import 'bootstrap/dist/css/bootstrap.css';
import './Form.css'

class AudienceForm extends Component {
    state = {
        loading: true,
        title: "Sign up to receive our text promotions",
        subtitle: "Get the latest news and updates about us every week",
        nameLabel: "",
        phoneLabel: "",
        buttonLabel: "Submit",
        buttonColor: "#00b0f0",
        privacyPolicy: "",
        logoURL: "",
        name: "",
        phone: "",
        invalidPhone: true,
        formFields: [],
        submitFlag: false
    };

    async componentDidMount() {
        // setup cookie
        const name = "locationId";
        const locationId = this.props.match.params.id;
        const expDays = 1;
        let expDate = new Date();
        expDate.setDate(expDate.getDate() + expDays);
        const cookie = `${name}=${locationId} ; expires=${expDate.toUTCString()}`
        const query = `
            query {
                location(id: "${locationId}"){
                    id
                    scans
                    code
                    tollFree {
                        id
                        phone
                    }
                    form {
                        id
                        title
                        subtitle
                        nameLabel
                        phoneLabel
                        buttonLabel
                        buttonColor
                        privacyPolicy
                        logoURL
                        formFields {
                            id
                            label
                            customField {
                                id
                                name
                                type
                            }
                        }
                    }
                }
            }`;

        await axios({
            url: process.env.REACT_APP_LEAD_WIRE_PRISMA,
            method: 'post',
            data: JSON.stringify({ query }),
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${process.env.REACT_APP_LEAD_WIRE_PRISMA_BEARER}`
            }
        }).then(
            (result) => {
                let location = result.data.data.location
                let form = location.form
                if (form) {
                    this.setState({
                        title: form.title,
                        subtitle: form.subtitle,
                        nameLabel: form.nameLabel,
                        phoneLabel: form.phoneLabel,
                        buttonLabel: form.buttonLabel,
                        buttonColor: form.buttonColor,
                        privacyPolicy: form.privacyPolicy,
                        logoURL: form.logoURL,
                        formFields: this.buildFormFieldsObj(form.formFields)
                    })
                }
                this.setState({
                    loading: false,
                    scans: location.scans,
                    tollFree: location.tollFree.phone,
                    code: location.code,
                    location
                })
            }
        ).catch(
            (err) => {
                console.log("Something went wrong with location query", err)
                window.location.href = 'error/404'
            }
        );
        if (document.cookie) {
            const browserCookie = this.getCookie(name);
            if (locationId !== browserCookie) {
                document.cookie = cookie;
                this.updateScanCount();
            }
        } else {
            document.cookie = cookie;
            this.updateScanCount();
        }
    }

    buildFormFieldsObj(fields) {
        const formFields = [];
        if (!!fields) {
            let customField;
            fields.forEach(field => {
                customField = field.customField;
                if (customField && customField.id) {
                    formFields.push({
                        id: customField.id,
                        label: field.label || customField.name || "",
                        type: customField.type,
                        invalid: false,
                        value: ""
                    });
                }
            })
        }
        return formFields;
    }

    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    updateScanCount = async () => {
        let locationId = this.props.match.params.id;
        let updateLocation = `
        mutation {
            updateLocation (
                id: "${locationId}",
                scans: ${this.state.scans ? this.state.scans + 1 : 1}
            ){
                id
            }
        }`
        await axios({
            url: process.env.REACT_APP_LEAD_WIRE_PRISMA,
            method: 'post',
            data: JSON.stringify({ query: updateLocation }),
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${process.env.REACT_APP_LEAD_WIRE_PRISMA_BEARER}`
            }
        }).then((result) => { }).catch((err) => { console.log("Something went wrong with location query", err) });
    }

    handleFormFieldChange(index, value) {
        this.setState((currentState) => {
            const formFields = currentState.formFields;
            if (index < formFields.length) {
                formFields[index].value = value;
                return { formFields };
            }
        })
    }

    handleFloatChange(index, value) {
        if (value.match(/^(?!0\d)(\d{0,8}(\.\d{0,2})?|\d{9}(\.\d{0,1})?|\d{10})$/)) {
            this.handleFormFieldChange(index, value);
        }
    }

    getFormFieldComponent(field, index) {
        if (!field || !field.type) return <></>;
        switch(field.type) {
            case "STRING":
                return (
                    <Form.Control 
                        className="round" 
                        type="text" 
                        value={field.value} 
                        onChange={(e) => this.handleFormFieldChange(index, e.target.value)}
                    />
                );
            case "FLOAT":
                return (
                    <Form.Control 
                        className="round" 
                        type="text" 
                        value={field.value} 
                        onChange={(e) => this.handleFloatChange(index, e.target.value)}
                    />
                );
            case "BOOLEAN":
                return (
                    <Form.Control 
                        as="select" 
                        className="round" 
                        defaultValue={field.value} 
                        onChange={(e) => this.handleFormFieldChange(index, e.target.value)}
                    >
                        <option disabled value="">Select an option...</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                    </Form.Control>
                );
            case "DATE":
                return (
                    <Form.Control 
                        className="round" 
                        type="date" 
                        value={field.value} 
                        onChange={(e) => {
                            if ((typeof e.target.value === "string") && e.target.value.length <= 10)
                                this.handleFormFieldChange(index, e.target.value);
                        }}
                    />
                );
            default:
                return <></>
        }
    }

    isValueType(type, value) {
        if (!value) return true;

        if (type === "STRING") {
            return (typeof value === 'string');
        }
        if (type === "FLOAT") {
            return !isNaN(parseFloat(value));
        }
        if (type === "BOOLEAN") {
            try {
                return (typeof JSON.parse(value) === "boolean");
            } catch {
                return false;
            }
        }
        if (type === "DATE") {
            const date = new Date(value);
            return (
                value.length === 10 
                && (date instanceof Date) 
                && !isNaN(date)
            )
        }
        return false;
    }

    validateForm() {
        let isValid = true, newState = {};
        const { invalidPhone, formFields } = this.state;

        if (invalidPhone) {
            isValid = false;
        }

        if (!!formFields && !!formFields.length) {
            newState.formFields = formFields.map(field => {
                if (!this.isValueType(field.type, field.value, true)) {
                    if (isValid) isValid = false;
                    field.invalid = true;
                }
                return field;
            })
        }

        this.setState(newState);

        return isValid;
    }

    getActualValue(type, value) {
        if (type === "FLOAT") return parseFloat(value);
        if (type === "BOOLEAN") return JSON.parse(value);
        if (type === "DATE") {
            const date = new Date();
            const [yy, mm, dd] = value.split("-");
            date.setFullYear(+yy, +mm - 1, +dd);
            return date.toISOString();
        }
        return value;
    }

    prepareCustomValues() {
        const { formFields } = this.state;
        const defaultValues = {
            stringValue: null,
            floatValue: null,
            booleanValue: null,
            dateValue: null,
        }

        const customValues = [];
        if (!!formFields && !!formFields.length) {
            let fieldName;
            formFields.forEach(async ({id, type, value}) => {
                if (!!id && !!type && !!value) {
                    fieldName = type.toLowerCase() + "Value";
                    customValues.push({ 
                        ...defaultValues,
                        organizationCustomFieldId: id,
                        [fieldName]: this.getActualValue(type, value)
                    });
                };
            })
        }
        return customValues;
    }

    handlePhoneChange(phone) {
        this.setState({
            phone,
            invalidPhone: (!phone || !phone.match(/[0-9]+/) || phone.length !== 11)
        })
    }

    createClient = async () => {
        
        if(this.validateForm() && !this.state.submitFlag) {
            this.setState({ submitFlag: true });
            
            const body = {
                to: [this.state.tollFree],
                from: this.state.phone,
                text: this.state.code,
                name: this.state.name,
                customValues: this.prepareCustomValues(),
                optedInFrom: "QR",
            }

            try {
                await axios({
                    url: process.env.REACT_APP_LEADWIRE_API + '/textReceive',
                    method: 'POST',
                    data: JSON.stringify(body),
                    headers: {
                        "Content-Type": "application/json"
                    },
                    json: true
                })
                window.location.href = '/success';
            } catch(err) {
                console.log("Client couldn't be created.", err);
                this.setState({ submitFlag: false });
            }

        }
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <Card className="p-4 shadow lw-card" >
                        <Card.Title className="text-center">
                            Loading...
                        </Card.Title>
                        <hr />
                        <Card.Subtitle className="text-muted text-center">
                            Please wait
                        </Card.Subtitle>
                    </Card>
                </div>
            )
        }
        return (
            <div className="form-parent">
                <Card className="form-container" >
                    <Card.Body className="form-component">
                        {!!this.state.logoURL ? (
                            <img className="form-logo" src={this.state.logoURL} alt="logo-url.png" />
                        ) : (
                            <></>
                        )
                        }   
                        <Card.Title className="text-center">
                            {this.state.title}
                        </Card.Title>
                        <Card.Subtitle className="text-muted text-center">
                            {this.state.subtitle}
                        </Card.Subtitle>
                        <br />
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            this.createClient();
                        }}>
                            {this.state.nameLabel !== "" &&
                                <FormGroup className="mb-3">
                                    <Form.Label> {this.state.nameLabel || "Name"} </Form.Label>
                                    <Form.Control onChange={(e) => { this.setState({ name: e.target.value }) }} type="text" className="round"/>
                                </FormGroup>
                            }
                            <FormGroup className="mb-3">
                                <Form.Label> {this.state.phoneLabel || 'Phone'} </Form.Label>
                                <PhoneInput
                                    inputClass="round phone-input"
                                    buttonClass="phone-button"
                                    dropdownClass="dropdown"
                                    country={'us'}
                                    preferredCountries={['us', 'pr']}
                                    masks={{ pr: '(...) ...-....' }}
                                    countryCodeEditable={false}
                                    value={this.state.phone}
                                    onChange={(phone) => this.handlePhoneChange(phone)}
                                    isValid={!this.state.invalidPhone}
                                />
                            </FormGroup>
                            {this.state.formFields.map((field, index) => {
                                return (
                                    <FormGroup key={field.id} className="mb-3">
                                        <Form.Label> {field.label} </Form.Label>
                                        {this.getFormFieldComponent(field, index)}
                                    </FormGroup>
                                )
                            })}
                            <Form.Group className="mb-3 form-terms">
                                <input type="checkbox" required /> {" "}
                                <span>
                                    By subscribing you agree to receive marketing text messages at the phone number provided.  
                                    Reply STOP to cancel or HELP for help. Message rates may apply. {" "}
                                    {
                                    !!this.state.privacyPolicy 
                                    ? 
                                        <>
                                        For more details please refer to our {" "}
                                        <a
                                            href={this.state.privacyPolicy}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Policy
                                        </a> 
                                        .
                                        </>
                                    : <></>
                                    }
                                </span>
                            </Form.Group>
                            <Button id="submitBtn" type="submit" className="col-12 round" disabled={this.state.submitFlag} style={{ backgroundColor: this.state.buttonColor, border: "none" }}>
                                {this.state.buttonLabel || "Submit"}
                                {this.state.submitFlag ? <div className="arc"/> : <></>}
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

export default AudienceForm;