// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qr-hash-container {
    align-items: center;
    justify-content: center;
    margin: 40px;
}

.qr-card{
    top: 5vh;
    border: none;
    width: 420px;
    border-radius: 10px;
    margin: 0;
    top: 10px;
}

.center{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.date-name-container {
    display: flex;
    flex-direction: column;
    
    margin-bottom: 0.65rem;
}

.redeem-note-container {
    font-size: small;
    font-weight: normal;
}`, "",{"version":3,"sources":["webpack://./src/views/Coupon/CouponQR.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,QAAQ;IACR,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,SAAS;IACT,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;;IAEtB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".qr-hash-container {\n    align-items: center;\n    justify-content: center;\n    margin: 40px;\n}\n\n.qr-card{\n    top: 5vh;\n    border: none;\n    width: 420px;\n    border-radius: 10px;\n    margin: 0;\n    top: 10px;\n}\n\n.center{\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n}\n\n.date-name-container {\n    display: flex;\n    flex-direction: column;\n    \n    margin-bottom: 0.65rem;\n}\n\n.redeem-note-container {\n    font-size: small;\n    font-weight: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
